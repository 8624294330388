<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        class="w-100 pt-6"
        ref="priceForm"
        v-model="isFormValid"
      >
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="tariffTypes"
              item-text="name"
              return-object
              label="Typ cennika"
              :disabled="dialog.data.update"
              v-model="tariff"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              placeholder="Wybierz typ cennika"
              dense
            />
          </v-col>

          <v-col
            v-if="tariff && tariff.name!=='Transport'"
            :class="{'pb-0': tariff && tariff.name==='Odpady'}"
            cols="12"
          >
            <v-autocomplete
              outlined
              :items="debrisTypes"
              :disabled="!tariff || dialog.data.update"
              :label="`Typ ${debrisType}`"
              v-model="data.debrisTypeId"
              :rules="[rules.required]"
              validate-on-blur
              :item-text="getDebrisString"
              item-value="id"
              append-icon="mdi-chevron-down"
              :placeholder="`Wybierz typ ${debrisType}`"
              dense
              :filter="searchDebrisType"
            />
          </v-col>

          <template v-if="tariff && tariff.name === 'Odpady'">
            <template v-if="containerTypes.length">
              <v-col class="my-0 py-0 pl-5">
                <v-input
                  class="containers-validation-input"
                  :value="data.prices"
                  :rules="[anyValue]"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
                v-for="(container,i) in containerTypes"
                :key="container.id"
              >
                <h3 class="font-weight-bold black--text mb-7 mt-0">
                  Kontener {{ container.name }}
                </h3>
                <v-text-field
                  outlined
                  label="Cena netto [zł]"
                  v-model.number="data.prices[i].price"
                  type="number"
                  :rules="[rules.money, rules.lowerThan(10000001)]"
                  validate-on-blur
                  placeholder="Wpisz cenę netto"
                />
              </v-col>
            </template>
            <v-col
              v-else
              class="input-label py-0 error--text"
            >
              Brak kontenerów dla danego oddziału
            </v-col>
          </template>

          <v-col
            cols="12"
            v-if="tariff && tariff.name === 'Transport'"
          >
            <v-text-field
              outlined
              label="Odległość od oddziału [km]"
              v-model.number="data.maximumDistance"

              :rules="[rules.required, rules.number, rules.lowerThan(10001)]"
              validate-on-blur
              placeholder="Wpisz odległość od oddziału"
            />
          </v-col>

          <v-col
            cols="12"
            v-if="tariff && tariff.name !== 'Odpady'"
          >
            <v-text-field
              outlined
              label="Cena netto [zł]"
              v-model.number="data.price"
              type="number"
              :disabled="!tariff"
              validate-on-blur
              :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
              placeholder="Wpisz cenę netto"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        v-if="dialog.data.update"
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="tariff && tariff.name === 'Odpady' && !containerTypes.length"
        @click="submitUpdatePriceForm()"
      >
        Edytuj cenę
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="tariff && tariff.name === 'Odpady' && !containerTypes.length"
        @click="submitNewPriceForm()"
      >
        Dodaj cenę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { parseAsBasicUnit, searchDebrisType, getDebrisString } from '../../utils'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      isFormValid: false,
      tariff: null,
      data: {
        maximumDistance: null,
        containerTypes: [],
        debrisTypeId: null,
        price: null,
        prices: []
      },
      tariffTypes: [
        { name: 'Odpady', tableName: 'debrisPrices' },
        { name: 'Kruszywo', tableName: 'aggregatePrices' },
        { name: 'Transport', tableName: 'transportPrices' }
      ]
    }
  },
  computed: {
    ...mapState({
      debrisTypes: state => state.debrisTypes.items,
      containerTypes: state => state.containerTypes.items,
      departmentId: state => state.price.departmentId,
      dialog: state => state.layout.dialog,
      isProcessing: state => state.debrisTypes.isProcessing,
      department: state => state.core.department
    }),
    debrisType () {
      return this.tariff.name === 'Odpady' ? 'odpadu' : 'kruszywa'
    }
  },
  watch: {
    tariff (newValue) {
      if (newValue.name !== 'Transport') {
        this.getDebrisTypes()
      }
    }
  },
  created () {
    this.getContainerTypes({ params: { departmentId: this.departmentId }, blockLoader: true })
      .then(() => {
        if (this.dialog.data.update) {
          const { tableName } = this.dialog.data || {}
          const { debrisType, price, prices, maximumDistance, id } = this.dialog.item || {}
          this.tariff = this.tariffTypes.find((tariff) => tariff.tableName === tableName)

          switch (this.tariff.name) {
            case 'Odpady':
              this.data = {
                id: null,
                debrisTypeId: debrisType.id,
                prices: this.containerTypes.map((type) => {
                  const container = prices.find((p) => p.containerTypeId === type.id)
                  return {
                    price: container ? container.price / 100 : null,
                    id: container ? container.id : null,
                    containerTypeId: type.id
                  }
                })
              }
              break
            case 'Transport':
              this.data = {
                id,
                price: price / 100,
                maximumDistance: maximumDistance / 1000
              }
              break
            case 'Kruszywo':
              this.data = {
                id,
                price: price / 100,
                debrisTypeId: debrisType.id
              }
              break
          }
        } else {
          this.data.prices = this.containerTypes.map((type) => {
            return {
              price: null,
              id: null,
              containerTypeId: type.id
            }
          })
        }
      })
  },
  methods: {
    // TODO refactor this component
    ...mapActions({
      addNewDebrisPrice: 'debrisPrices/addNewItem',
      addNewAggregatePrice: 'aggregatePrices/addNewItem',
      addNewTransportPrice: 'transportPrices/addNewItem',
      closeDialog: 'layout/closeDialog',
      getContainerTypes: 'containerTypes/getItems',
      // TODO there are better options than this, however submitUpdatePriceForm method should be refactored first
      editItem (dispatch, payload) {
        return dispatch(`${payload.tableName}/editItem`, payload)
      },
      // tableName can be either aggregatePrices, debrisPrices or transportPrices
      getPrices (dispatch, payload) {
        return dispatch(`${payload.tableName}/getItems`)
      },
      getDebrisTypes (dispatch) {
        const params = {
          aggregate: this.tariff.name !== 'Odpady',
          withoutPrices: !this.dialog.data.update,
          departmentId: this.departmentId
        }
        this.debrisTypeId = null
        return dispatch('debrisTypes/getItems', { params })
      }
    }),
    anyValue (arrayValue) {
      return !!arrayValue?.filter(val => !!val.price).length || 'Podaj przynajmniej jedną cenę.'
    },
    searchDebrisType,
    getDebrisString,
    createNewPrice (tableName) {
      // TODO create processParams method and reuse it in updatePrice method
      const { price, prices, maximumDistance } = this.data || {}
      const params = {
        ...this.data,
        departmentId: this.departmentId,
        maximumDistance: parseAsBasicUnit(maximumDistance, 1000),
        price: parseAsBasicUnit(price),
        prices: prices
          .filter((p) => p.price)
          .map((p) => {
            return { ...p, price: parseAsBasicUnit(p.price) }
          })
      }
      switch (tableName) {
        case 'debrisPrices':
          delete params.maximumDistance
          delete params.price
          return this.addNewDebrisPrice({ params })
        case 'transportPrices':
          delete params.debrisTypeId
          delete params.prices
          return this.addNewTransportPrice({ params })
        case 'aggregatePrices':
          delete params.maximumDistance
          delete params.prices
          return this.addNewAggregatePrice({ params })
      }
    },
    submitNewPriceForm () {
      this.$refs.priceForm.validate()
      if (this.isFormValid) {
        const { tableName } = this.tariff || {}

        this.createNewPrice(tableName)
          .then(() => {
            this.getPrices({ tableName })
            this.afterFormSubmitted('Utworzono nową cenę')
          })
      }
    },
    submitUpdatePriceForm () {
      this.$refs.priceForm.validate()
      if (this.isFormValid) {
        const { tableName } = this.tariff || {}
        const { id, ...params } = this.data || {}
        params.departmentId = this.departmentId
        if (params.maximumDistance) params.maximumDistance = parseAsBasicUnit(params.maximumDistance, 1000)
        let method = this.editItem
        if (tableName === 'debrisPrices') {
          // In this case BE requires to replace pricing with the new one
          method = this.addNewDebrisPrice
          params.prices = params.prices
            .filter((p) => p.price)
            .map((p) => {
              return { ...p, price: parseAsBasicUnit(p.price) }
            })
        } else {
          params.price = parseAsBasicUnit(params.price)
        }

        method({ params, id, tableName })
          .then(() => {
            this.afterFormSubmitted('Zaktualizowano cenę')
            this.getPrices({ tableName })
            this.closeDialog()
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.containers-validation-input::v-deep .v-input__slot {
  display:none !important;
}
</style>
